import { CwUtils } from "cw-utils";

import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { PageOptions } from "../../../util/backend-util-module";
import { ApiGateway } from "../../../api-gateway";
import { Group } from "../../../models/backend-definitions.module";

@Injectable()
export class GroupListProvider implements Resolve<any> {
    group: Group[];
    groupEdit: Group;
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(public gw: ApiGateway, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.groupEdit = this.state.currentGroup;

        let currentPage = this.state.currentPage;

        if (currentPage) {
            this.pageOptions = currentPage;
            this.currentPage = currentPage;
        } else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = "name";
        }

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getPage(this.pageOptions)]).then(() => {
                resolve();
            }, reject);
        });
    }

    get(id: number, entity): Observable<any> {
        if (!id) {
            return null;
        }
        let path;
        if (entity) {
            path = entity + "group/wAll/:id";
        } else {
            path = "group/wAll/:id";
        }
        return this.gw.get(path, { id: id });
    }

    get currentGroup(): Group {
        return this.groupEdit;
    }

    getAll(entity?): Promise<any> {
        let path;
        if (entity) {
            path = entity + "group/";
        } else {
            path = "group/";
        }
        return new Promise((resolve, reject) => {
            this.gw.get(path).subscribe((res: HttpResponse<any>) => {
                this.group = res.body;
                resolve(res);
            }, reject);
        });
    }

    getMainGroup(entity?: String): Promise<any> {
        let path;
        if (entity) {
            path = entity + "group/mainGroup";
        } else {
            path = "group/mainGroup";
        }
        return new Promise((resolve, reject) => {
            this.gw.get(path).subscribe((res: HttpResponse<any>) => {
                this.group = res.body;
                resolve(res);
            }, reject);
        });
    }

    getSubGroup(groupId, entity?: String): Promise<any> {
        let path;
        if (entity) {
            path = entity + "group/subGroup/" + groupId;
        } else {
            path = "group/subGroup/" + groupId;
        }
        return new Promise((resolve, reject) => {
            this.gw.get(path).subscribe((res: HttpResponse<any>) => {
                this.group = res.body;
                resolve(res);
            }, reject);
        });
    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("group/search", pageOptions)
                .subscribe((res: HttpResponse<any>) => {
                    if (
                        !CwUtils.compare(this.group, res.body.content) &&
                        res.body.content.length > 0
                    ) {
                        this.group = res.body.content;
                        this.onDataChanged.next(this.group);
                    } else {
                        this.group = res.body.content;
                    }
                    resolve(res);
                }, reject);
        });
    }

    getPageSimpleList(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("group/search/simple-list", pageOptions).subscribe({
                next: (res) => {resolve(res)},
                error:(err)=>{reject(err)}
            });
        });
    }

    getPageSettings() {
        return this.pageOptions;
    }

    getGroupTypeAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("group/groupType/simpleList").subscribe({
                next: (res) => {resolve(res)},
                error:(err)=>{reject(err)}
            });
        });
    }
}
