
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Patient, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class PatientListProvider implements Resolve<any>
{
    patient: Patient[];
    patientEdit: Patient;
    onDataChanged: BehaviorSubject<any>;
    onHoraryChanged: BehaviorSubject<any>;
    onTypeChanged: BehaviorSubject<any>;
    onPerformerChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.onPerformerChanged = new BehaviorSubject({});
        this.onTypeChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)){
            this.currentPage = this.state.currentPage;
        }
        this.patientEdit = this.state.currentPatient;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'person.name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                // this.getPage(this.pageOptions)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<any> {
        if (!id) {
            return null;
        }
        return this.gw.get('patient/:id', { id: id });
    }

    getById(id: number): Observable<HttpResponse<Patient>> {
        if (!id) {
            return null;
        }
        return this.gw.get('patient/wAll/:id', { id: id });
    }

    getSimpleObjById(id: number): Observable<HttpResponse<Patient>> {
        if (!id) {
            return null;
        }

        return this.gw.get('patient/simpleObj/:id', { id: id });
    };

    get currentPatient(): Patient {
        return this.patientEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('patient/').subscribe((res: HttpResponse<any>) => {
                this.patient = res.body;
                resolve(res);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('patient/search', pageOptions).subscribe((res: HttpResponse<any>) => {

                //patient/searchForAttendance
                if (!CwUtils.compare(this.patient, res.body.content) && res.body.content.length > 0) {
                    this.patient = res.body.content;
                    this.onDataChanged.next(this.patient);
                }
                else {
                    this.patient = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }


    getPageNew(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('patient/search/new', pageOptions).subscribe((res: HttpResponse<any>) => {

                //patient/searchForAttendance
                if (!CwUtils.compare(this.patient, res.body.content) && res.body.content.length > 0) {
                    this.patient = res.body.content;
                    this.onDataChanged.next(this.patient);
                }
                else {
                    this.patient = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }


    getPageActive(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('patient/search-active', pageOptions).subscribe((res: HttpResponse<any>) => {

                //patient/searchForAttendance
                if (!CwUtils.compare(this.patient, res.body.content) && res.body.content.length > 0) {
                    this.patient = res.body.content;
                    this.onDataChanged.next(this.patient);
                }
                else {
                    this.patient = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }


    getPageSimpleList(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('patient/search/simpleList', pageOptions).subscribe((res: HttpResponse<any>) => {

                //patient/searchForAttendance
                if (!CwUtils.compare(this.patient, res.body.content) && res.body.content.length > 0) {
                    this.patient = res.body.content;
                    this.onDataChanged.next(this.patient);
                }
                else {
                    this.patient = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }

    getPatientDuplicateNameBirthdayMother(patientTo): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('patient/patient-duplicate', patientTo)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (error) => {reject(error)}
                })
        });
    }

    getPagePatient(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('patient/search/response', pageOptions).subscribe((res: HttpResponse<any>) => {

                //patient/searchForAttendance
                if (!CwUtils.compare(this.patient, res.body.content) && res.body.content.length > 0) {
                    this.patient = res.body.content;
                    this.onDataChanged.next(this.patient);
                }
                else {
                    this.patient = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }

    getPagePatientProfessional(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('patient/search/module/professional', pageOptions).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getPagePatientActived(pageOptions: PageOptions, performerId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`patient/search/patientActived/${performerId}`, pageOptions).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getPersonImageListId(personId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('patient/person/:personId/image/search', {personId: personId}).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });

    };

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }
}
 

