import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { SnackBarService } from '../../cw-utils/snackbar.service';
import { UserService } from '../../cw-backend/providers/security/user/user-backend-module';
import { ViewListService } from '../../cw-backend/providers/security/view/view-list-service';
import { View } from '../../cw-backend/models/backend-definitions.module';

@Directive()
@Injectable()
export class RolePermissionService {
    @Output() formEvent: EventEmitter<any>;
    @Output() scheduleCalendarEvent: EventEmitter<any>;
    @Output() chatMessageEvent: EventEmitter<any>;
    @Output() updateProfileEvent: EventEmitter<any>;
    @Output() userLogged: EventEmitter<any>;
    @Output() patientTalkEvent: EventEmitter<any>;
    user: any;
    view: View;
    viewList: View[];
    restrictableComponentList: any;
    transactionList: any[];
    transactionsPermited: any;
    permissionList: any;

    formGroup: Array<any> = [];
    currentViewHasPermission: boolean;

    constructor(
        private router: Router,
        private snackBar: SnackBarService,
        private userService: UserService,
        private viewListService: ViewListService,
    ) {
        this.formEvent = new EventEmitter;
        this.chatMessageEvent = new EventEmitter;
        this.updateProfileEvent = new EventEmitter;
        this.userLogged = new EventEmitter;
        this.patientTalkEvent = new EventEmitter;
        this.scheduleCalendarEvent = new EventEmitter;
    }

    async rolePermissions(viewCode?, currentViewId?) {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (!this.user) {
            return null
        }

        if (currentViewId) {
            this.view = this.viewList.find(view => view.id === currentViewId);
        }
        else if (viewCode) {
            this.viewList = JSON.parse(localStorage.getItem('views'));
            if (!this.viewList) {
                return null
            }
            this.view = this.viewList.find(view => view.code === viewCode);
        }

        this.getRestrictedComponents(this.view);

        await this.getTransactions(this.view);


        return this.formGroup;
    }

    async getRestrictedComponents(view) {

        this.formGroup = new Array();
        this.userService.getRestrictedComponents(view.id).then(res => {
            let restrictableComponents = res.body;

            if (restrictableComponents.length > 0) {
                restrictableComponents.forEach(element => {

                    let componentCode: String = element.code.split('-');
                    let formName: String = componentCode[componentCode.length - 3]
                    let componentType = componentCode[componentCode.length - 1];

                    // let formName: String = $("#" + element.code).attr('formControlName'); 
                    let formDisabled: Boolean = true;

                    // hidden components
                    if (element.restrictionType === 'H') {

                        $("#" + element.code).hide()
                        $("#" + element.code).prop('disabled', true);
                        // save form field name for future check
                        if (componentType.startsWith('form')) {
                            this.formGroup.push({ name: formName, restriction: true, code: element.code, restrictionType: element.restrictionType });
                        }
                    } else if (element.restrictionType === 'D') {
                            
                            $("#" + element.code).show()
                            $("#" + element.code).prop('disabled', true);
                        // $("#" + element.code).show()
                        // $("#" + element.code).attr('disabled', 'disabled');
                        // save form field name for future check
                        if (componentType.startsWith('form')) {
                            this.formGroup.push({ name: formName, restriction: true , code: element.code, restrictionType:element.restrictionType });
                        }
                    }
                    else if (element.restrictionType == null) {
                        $("#" + element.code).show()
                        $("#" + element.code).prop('disabled', false);
                        if (componentType.startsWith('form')) {
                            this.formGroup.push({ name: formName, restriction: false, code: element.code, restrictionType:element.restrictionType });
                        }
                    }
                });
                this.formEvent.emit(this.formGroup);
            }
        });
        return this.formGroup;
    }

    async getTransactions(view, viewCode?) {

          this.viewListService.getTransactions(view.id).then(response => {
            this.transactionList = response.body;
        }).finally(() => {

            this.userService.getTransactions(view.id).then(response => {
                this.transactionsPermited = response.body;
                if (this.transactionsPermited) {

                    let search = this.transactionsPermited.findIndex(transaction => transaction.code.startsWith('search'));
                    let find = this.transactionsPermited.findIndex(transaction => transaction.code.startsWith('find'));
                    let insert = this.transactionsPermited.findIndex(transaction => transaction.code.startsWith('insert'));
                    let update = this.transactionsPermited.findIndex(transaction => transaction.code.startsWith('update'));

                    if (search != -1) {
                        $('#' + view.code + ' :input').prop('readOnly', false);
                    } else if (find == -1) {
                        $('#' + view.code + ' :input').prop('readOnly', true);
                    } else if (insert != -1 || update != -1) {
                        $('#' + view.code + ' :input').prop('readOnly', false);
                    } else {
                        $('#' + view.code + ' :input').prop('readOnly', true);
                    }
                }
            }).finally(async () => {
                if (this.transactionList) {
                    this.transactionList.forEach(element => {
                        const index = this.transactionsPermited.findIndex(transaction => transaction.id === element.id)
                        if (index != -1) {
                            $("#" + element.code).prop('disabled', false);
                            $("#" + element.code).css('color', "#ffff");
                            $("#" + element.code).css('cursor', 'pointer');
                        } else {
                            $("#" + element.code).prop('disabled', true);
                        }
                    });
                }

                let viewType: any = view.code.split('-');
                viewType = viewType[viewType.length - 1]
                if (viewType == 'list') {
                    let viewEdit = view.code.replace('list', 'edit');
                    await this.checkInsertTransaction(viewEdit);
                }
            });
        });
    }

    scheduleChanged(schedule) {
        this.formEvent.emit(schedule);
    }

    scheduleCalendar(schedule){
        this.scheduleCalendarEvent.emit(schedule)
    }

    userLogin(id) {
        this.userLogged.emit(id);
    }
    chatMessage(message) {
        this.chatMessageEvent.emit(message);
    }

    updateProfileMessage(message) {
        this.updateProfileEvent.emit(message);
    }

    patientTalk(message) {
        this.patientTalkEvent.emit(message);
    }

    reloadPermissions() {
        this.viewList = new Array();
        this.userService.getPermissions().then(res => {
            let modules = new Array;
            modules = res.body.modules;
            localStorage.setItem('modules', JSON.stringify(modules));
            localStorage.removeItem('views');

            this.currentViewHasPermission = false;

            modules.forEach(module => {
                module.subModules.forEach(subModules => {
                    subModules.views.forEach((view) => {
                        this.viewList.push(view);
                        localStorage.removeItem(view.code);

                        let viewType: any = view.code.split('-');
                        viewType = viewType[viewType.length - 1]

                        if (viewType !== 'list' && this.router.url.startsWith(view.path)) {
                            this.rolePermissions(null, view.id)
                            this.currentViewHasPermission = true;
                        }
                        else if (view.path === this.router.url) {
                            this.rolePermissions(null, view.id).then(() => {
                                if (viewType == 'list') {
                                    let viewEdit = view.code.replace('list', 'edit');
                                    this.checkInsertTransaction(viewEdit);
                                }
                            })
                            this.currentViewHasPermission = true;
                        }
                    });
                });
            });

            if (!this.currentViewHasPermission) {
                this.snackBar.warnMessage("Acesso negado");
                this.router.navigate(['aplicacao/dashboards/admin']);
            }
            localStorage.setItem('views', JSON.stringify(this.viewList));
        })
    }

    async checkInsertTransaction(viewCode) {
        this.view = this.viewList.find(view => view.code === viewCode);
        if(!this.view){
            return;
        }
        this.userService.getTransactions(this.view.id).then(response => {
            this.transactionsPermited = response.body;

            let insert = this.transactionsPermited.find(transaction => transaction.code.startsWith('insert'));
            if (insert != null) {
                $("#" + insert.code).prop('disabled', false);
                $("#" + insert.code).css('color', '#fff');
                $("#" + insert.code).css('cursor', 'pointer');
            } else {
                $("#insert-" + viewCode).prop('disabled', true);
                $("#insert" + viewCode).css('color', '##585555b0');
            }
        });
    }
}
