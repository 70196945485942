import { SnackBarService } from './../../../../../shared/cw-utils/snackbar.service';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { Message, Profile, ProfileChatTo } from '../../../../../shared/cw-backend/models/security/user/profile';
import { ProfileProvider } from '../../../../../shared/cw-backend/providers/security/profile/profile.provider';
import { RolePermissionService } from '../../../../../shared/cw-services/role/role-permission.service';
import { CwUtils } from 'cw-utils';


@Component({
    selector: 'chat-panel',
    templateUrl: './chat-panel.component.html',
    styleUrls: ['./chat-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    profileList = new Array<ProfileChatTo>();
    chat = new Array<Message>();
    selectedProfile: ProfileChatTo;
    sidebarFolded: boolean;
    user: any;
    profile: Profile;

    showChat = false;
    chatSearch: any;
    searchText: string;



    @ViewChild('replyForm')
    private _replyForm: NgForm;

    @ViewChild('replyInput')
    private _replyInput: ElementRef;


    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatPanelService} _chatPanelService
     * @param {ProfileProvider} profileProvider
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _chatPanelService: ChatPanelService,
        private _fuseSidebarService: FuseSidebarService,
        private profileProvider: ProfileProvider,
        private _sanitizer: DomSanitizer,
        private rolePermissionService: RolePermissionService,
        private snackBarService : SnackBarService
    ) {
        // Set the defaults
        this.selectedProfile = null;
        this.sidebarFolded = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.chatSearch = {
            name: ''
        };
        this.searchText = '';

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {


        this.rolePermissionService.userLogged
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                // distinctUntilChanged()
            ).
        subscribe({
            next: (userLogged) => {

                this.getProfiles();
                this.getUserLogged();

            },
            error: (err: any) => {
                console.error(err);
            },
        })

        this.rolePermissionService.chatMessageEvent
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()).subscribe({
            next: (chatMessage) => {
                let message: Message = JSON.parse(chatMessage);
                if (message.profileTo.id == this.profile.id && message.profileFrom.id == this.selectedProfile?.id) {
                    this.chat.push(message);
                    this.readedMessage(this.selectedProfile.id, this.profile.id);
                    this._chatPanelService.playNotification();
                    this._prepareChatForReplies();
                } else if (message.profileTo.id == this.profile.id && message.profileFrom.id != this.selectedProfile?.id) {
                    const index = this.findIndexProfile(message.profileFrom.id);
                    if (index >= 0) {
                        this.profileList[index].unread = this.profileList[index].unread + 1;
                        let title = this.profileList[index].unread  == 1 ? `${ this.profileList[index].unread} nova mensagem de:` : `${ this.profileList[index].unread} novas mensagens de:`
                        this._chatPanelService.playNotification();
                        this.snackBarService.notifierMessage('simpleMessage',title, Array(message.profileFrom.name),6000);
                    }
                }

                this.sortProfileList();

            },
            error: (err: any) => {
                console.error(err);
            },
        })


        this.rolePermissionService.updateProfileEvent.pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(500),
            distinctUntilChanged()).subscribe({
            next: (profileChat) => {
                let profileChatTo: ProfileChatTo = JSON.parse(profileChat);
                if (profileChatTo.profile.id != this.profile.id) {
                    const index = this.findIndexProfile(profileChatTo.profile.id)
                    if (index >= 0) {
                        this.profileList[index].status = profileChatTo.status;
                        this.profileList[index].profile.name = profileChatTo.profile.name;
                        this.profileList[index].avatar = profileChatTo.avatar;
                    }
                }
                this.sortProfileList();



            },
            error: (err: any) => {
                console.error(err);
            },
        })

        // // setTimeout(() => {
        // //     this.getUser();

        // // }, 2000);

        // // Load the contacts
        // // this._chatPanelService.loadContacts().then(() => {

        // //        this.user = this._chatPanelService.user;
        // //      const contacts = this._chatPanelService.contacts;
        // //       });

        // // Subscribe to the foldedChanged observable
        this._fuseSidebarService.getSidebar('chatPanel').foldedChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((folded) => {
                this.sidebarFolded = folded;
            });
    }


    getUserLogged() {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user) {
            this.getProfileByUserId()
        }

    }
    getProfiles() {
        this.profileProvider.getProfileAll().then(res => {
            this.profileList = res.body;
        });
    }

    sortProfileList() {
        this.profileList
            .sort((profA, profB) => {
                let a = (profA.unread > 0 ? 5 : 0) +
                    (profA.status == "online" ? 4 : 0) +
                    (profA.status == "away" ? 3 : 0) +
                    (profA.status == "do-not-disturb" ? 2 : 0) +
                    (profA.status == "offline" && profA.unread > 0 ? 5 : -1)
                let b = (profB.unread > 0 ? 5 : 0) +
                    (profB.status == "online" ? 4 : 0) +
                    (profB.status == "away" ? 3 : 0) +
                    (profB.status == "do-not-disturb" ? 2 : 0) +
                    (profB.status == "offline" && profA.unread > 0 ? 5 : -1)

                return a == b ? 0 : a > b ? -1 : 1;
            })
    }

    checkImagem(avatar) {
        let image = false
        if (avatar != null) {
            // let imageFilter = personImagemList.filter(pi => pi.main === true);
            let imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + avatar);
            return imagePath;
        }
        return image;
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------



    /**
     * Prepare the chat for the replies
     */
    private _prepareChatForReplies(speed?): void {
        setTimeout(() => {

            // Focus to the reply input
            this._replyInput.nativeElement.focus();

            // Scroll to the bottom of the messages list
            if (this._chatViewScrollbar) {
                this._chatViewScrollbar.update();
                speed = speed || 400;
                setTimeout(() => {
                    this._chatViewScrollbar.scrollToBottom(0, speed);
                });
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
        this.showChat = !this.showChat;
    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
        if (this.showChat == false){
            this.showChat = !this.showChat;
        }
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
    }

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {


        return (
            message.profileFrom.id === this.selectedProfile.id &&
            ((this.chat[i + 1] && this.chat[i + 1].profileFrom.id !== this.selectedProfile.id) || !this.chat[i + 1])
        );
        // message.who === this.selectedProfile.id &&
        // ((this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== this.selectedProfile.id) || !this.chat.dialog[i + 1])
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {

        return (i === 0 || this.chat[i - 1] && this.chat[i - 1].profileFrom.id !== message.profileFrom.id);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.chat.length - 1 || this.chat[i + 1] && this.chat[i + 1].profileFrom.id !== message.profileFrom.id);
    }

    findIndexProfile(profileId) {
        return this.profileList.findIndex(p => p.id == profileId);
    }
    /**
     * Toggle chat with the contact
     *
     * @param contact
     */
    async toggleChat(contact: ProfileChatTo): Promise<void> {
        const index = this.findIndexProfile(contact.id);
        if (index >= 0) {
            contact.unread = 0;
            this.profileList[index] = contact;
        }
        // If the contact equals to the selectedContact,
        // that means we will deselect the contact and
        // unload the chat
        if (this.selectedProfile && contact.id === this.selectedProfile.id) {
            // Reset
            this.resetChat();
        }
            // Otherwise, we will select the contact, open
        // the sidebar and start the chat
        else {
            // Unfold the sidebar temporarily
            this.unfoldSidebarTemporarily();

            // Set the selected contact
            this.selectedProfile = contact;

            // Load the chat
            this.profileProvider.getMessages(this.profile.id, contact.id)
                .then(res => {
                    this.chat = res.body;
                    this._prepareChatForReplies();
                    this.readedMessage(this.selectedProfile.id, this.profile.id);
                });
            // this._chatPanelService.getChat(contact.id).then((chat) => {

            // Set the chat
            //     this.chat = chat;

            //      // Prepare the chat for the replies
            //    this._prepareChatForReplies();
            //   });
        }
    }

    readedMessage(profileFrom, profileTo) {
        this._chatPanelService.readedMessage(profileFrom, profileTo)
            .then(res => {

            })
    }

    getProfileByUserId() {
        return new Promise<any>((resolve, reject) => {
            this.profileProvider.getByUserId(this.user.id)
                .then(res => {
                    this.profile = res.body;

                });
        });
    }

    /**
     * Remove the selected contact and unload the chat
     */
    resetChat(): void {
        // Set the selected contact as null
        this.selectedProfile = null;

        // Set the chat as null
        this.chat = null;
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();

        if (!this._replyForm.form.value.message) {
            return;
        }

        // Message
        let message = {
            id: null,
            profileFrom : CwUtils.extractId(this.profile),
            profileTo : CwUtils.extractId(this.selectedProfile),
            message : this._replyForm.form.value.message,
            sended : null,
            readed : null,
        };

        //send message
        this._chatPanelService.sendMessage(message)
            .then(res => {
                if (res.body) {
                    // Add the message to the chat
                    this.chat.push(res.body);
                    this._replyForm.reset();
                    this._prepareChatForReplies();
                }
            })

        // Reset the reply form


        // Update the server
        // this._chatPanelService.updateChat(this.chat.id, this.chat.dialog).then(response => {

        //     // Prepare the chat for the replies
        //     this._prepareChatForReplies();
        // });
    }


}
