import { AuthService } from "./../../shared/cw-services/auth/auth.service";
import { AdminDashboardComponent } from "./main/modules/application/dashboards/admin/admin-dashboard.component";
import { FuseUtils } from "./../@fuse/utils/index";
import { ApiGateway } from "./../../shared/cw-backend/api-gateway";
import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Router } from "@angular/router";
import { SnackBarService } from "cw-utils";
import {
    UserService,
    WebSocketService,
    ParameterProvider,
    ScheduleListProvider,
} from "cw-backend";
import { RolePermissionService } from "cw-services";
import { ShortcutEventOutput, ShortcutInput } from "ng-keyboard-shortcuts";
import moment from "moment";
import { TenantService } from "./tenant/tenant.service";
moment.locale("pt");

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    numberRequest: number;

    // idle
    timedOut = false;
    lastPing?: Date = null;

    // Web Socket
    greeting: any;
    name: string;
    // webSocket: WebSocketService;

    shortcuts: ShortcutInput[] = [];

    paramters: any[];
    loadedSystemParams: boolean;
    timeLockParamter: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private idle: Idle,
        private keepalive: Keepalive,
        private router: Router,
        private gw: ApiGateway,
        private snackBar: SnackBarService,
        private userService: UserService,
        private _changeDetectorRef: ChangeDetectorRef,
        private rolePermissionService: RolePermissionService,
        private authService: AuthService,
        public parameterProvider: ParameterProvider,
        private scheduleListProvider: ScheduleListProvider,
        private tenantService: TenantService,
        private webSocket: WebSocketService
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add languages
        this._translateService.addLangs(["en", "tr"]);

        // Set the default language
        this._translateService.setDefaultLang("en");

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            navigationEnglish,
            navigationTurkish
        );

        // Use a language
        this._translateService.use("en");

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // // Opera 8.0+
        // var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

        // // Firefox 1.0+
        // var isFirefox = typeof InstallTrigger !== 'undefined';

        // // Safari 3.0+ "[object HTMLElementConstructor]"
        // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

        // // Internet Explorer 6-11
        // var isIE = /*@cc_on!@*/false || !!document.documentMode;

        // // Edge 20+
        // var isEdge = !isIE && !!window.StyleMedia;

        // // Chrome 1 - 79
        // var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        // // Edge (based on chromium) detection
        // var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

        // // Blink engine detection
        // var isBlink = (isChrome || isOpera) && !!window.CSS;

        // var output = 'Detecting browsers by ducktyping:<hr>';
        // output += 'isFirefox: ' + isFirefox + '<br>';
        // output += 'isChrome: ' + isChrome + '<br>';
        // output += 'isSafari: ' + isSafari + '<br>';
        // output += 'isOpera: ' + isOpera + '<br>';
        // output += 'isIE: ' + isIE + '<br>';
        // output += 'isEdge: ' + isEdge + '<br>';
        // output += 'isEdgeChromium: ' + isEdgeChromium + '<br>';
        // output += 'isBlink: ' + isBlink + '<br>';
        // document.body.innerHTML = output;

        // this.webSocket = new WebSocketService();
        this.webSession();

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                if (this.authService.isAuthenticated) {
                    this.webSocketConnect();
                    setTimeout(() => {
                        this.rolePermissionService.userLogin(true);
                    }, 3000);
                }
                this.findParamters();
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.fuseConfig.layout.style = "horizontal-layout-1";

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    ngAfterViewInit() {
        this.userService.userAction
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((action) => {
                if (action == "LOGOUT") {
                    this.webSocketDisconnect();
                }
            });

        this.webSocket.messageReceived
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                this.webSocketHandleMessage(res);
            });

        this.gw.tokenExpired
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                localStorage.clear();
                this.snackBar.errorMessage(
                    "Sessão expirada. Favor acessar novamente."
                );
                this.router.navigate(["auth/login"]);
            });

        // this.shortcuts.push(
        //     {
        //         key: ["?"],
        //         label: "Help",
        //         description: "Question mark",
        //         command: e => ,
        //         preventDefault: true
        //     },
        //     {
        //         key: ["up up down down left right left right b a enter"],
        //         label: "Sequences",
        //         description: "Konami code!",
        //         command: (output: ShortcutEventOutput) =>
        //     },
        //     {
        //         key: ["cmd + b"],
        //         label: "Help",
        //         description: "Cmd + b",
        //         command: e => console.info(e),
        //         preventDefault: true
        //     }
        // );
    }

    webSocketConnect() {
        this.webSocket._connect();
    }

    webSocketDisconnect() {
        this.webSocket._disconnect();
    }

    webSocketSendMessage() {
        this.webSocket._send("NOT IMPLEMENTED");
    }

    webSocketHandleMessage(message) {
        let messageReceived = message.body;
        this.userService.doAction(messageReceived);
        if (message == false) {
            this.webSocketConnect();
        }
        const tenantPath = this.tenantService.getTenant();

        if (
            message.headers.destination ===
            `/topic/refreshSchedule-${tenantPath}`
        ) {
            this.rolePermissionService.scheduleChanged(message.body);
            return;
        }


        if (
            message.headers.destination ===
            `/topic/refreshScheduleCalendar-${tenantPath}`
        ) {
            this.rolePermissionService.scheduleCalendar(message.body);
            return;
            // this.userService.scheduleAction(message.body);
            // this.scheduleListProvider.onDataChanged.next(message.body);
        }

        // TODO - ALIGN WITH BARAUNA
        // this.adminDashboard.doAction(messageReceived);

        if (messageReceived == "RELOAD_PERMISSION") {
            this.rolePermissionService.reloadPermissions();
            let key = FuseUtils.generateGUID();
            this.navigation =
                this._fuseNavigationService.getCurrentNavigation();
            this._fuseNavigationService.register(key, this.navigation);
            this._fuseNavigationService.setCurrentNavigation(key);
            return;
        }

        if (message.headers.destination === `/user/topic/reply-${tenantPath}`) {
            this.rolePermissionService.userLogin(message.body);
            return;
        }

        if (
            message.headers.destination === `/user/topic/message-${tenantPath}`
        ) {
            this.rolePermissionService.chatMessage(message.body);
            return;
        }

        if (
            message.headers.destination === `/topic/updateProfile-${tenantPath}`
        ) {
            this.rolePermissionService.updateProfileMessage(message.body);
            return;
        }
        if (
            message.headers.destination === `/topic/talkPatient-${tenantPath}`
        ) {
            this.rolePermissionService.patientTalk(message.body);
            return;
        }
        if (
            message.headers.destination ===
            `/topic/refreshParameter-${tenantPath}`
        ) {
            this.getSystemParamters();
            return;
        }
    }

    idleSettings(lockTime) {
        this.idle.setIdle(lockTime);
        this.idle.setTimeout(5);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe((res) => console.info(res));
        this.idle.onTimeout.subscribe(() => {
            this.timedOut = true;
            this.router.navigate(["auth/tela-de-bloqueio"]);
            if (this.router.url !== "/auth/tela-de-bloqueio") {
                localStorage.setItem("last_navigation", this.router.url);
            }
            this.resetIdle();
        });
        this.idle.onIdleStart.subscribe(() =>
            console.info("Sessão entrado em estado de inatividade")
        );
        this.idle.onTimeoutWarning.subscribe((countdown) =>
            console.info("Sua sessão irá expirar em" + countdown)
        );

        this.keepalive.interval(15);

        this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

        this.resetIdle();
    }

    resetIdle() {
        this.idle.watch();
        this.timedOut = false;
    }

    async webSession() {
        let variableDelay: number = Math.floor(Math.random() * 5000 + 1000);
        await this.delay(variableDelay);

        let sessionId = sessionStorage.getItem("sessionId");
        if (!sessionId) {
            sessionStorage.setItem(
                "sessionId",
                JSON.stringify(FuseUtils.generateGUID())
            );
            let webSessionCount = JSON.parse(
                localStorage.getItem("user_sessions")
            );
            if (!webSessionCount) {
                localStorage.setItem("user_sessions", JSON.stringify(1));
            } else {
                localStorage.setItem(
                    "user_sessions",
                    JSON.stringify(webSessionCount + 1)
                );
            }
        }
    }

    findParamters() {
        let token = localStorage.getItem("token");
        let loadedSystemParams = JSON.parse(
            localStorage.getItem("load_system_params")
        );

        if (token && !loadedSystemParams) {
            this.getSystemParamters();
            localStorage.setItem(
                "load_system_params",
                JSON.stringify((this.loadedSystemParams = true))
            );
        } else if (token) {
            this.paramters = JSON.parse(localStorage.getItem("system_params"));
            this.timeLockParamter = this.paramters.find(
                (paramter) => paramter.name === "TIME_LOCK_INACTIVE"
            );
            if (this.timeLockParamter) {
                let lockTime = parseInt(this.timeLockParamter.value) * 60;
                this.idleSettings(lockTime);
            }
        }
    }

    getSystemParamters() {
        this.parameterProvider.getAll().then((res) => {
            this.paramters = res.body;
            localStorage.setItem(
                "system_params",
                JSON.stringify(this.paramters)
            );

            this.timeLockParamter = this.paramters.find(
                (paramter) => paramter.name === "TIME_LOCK_INACTIVE"
            );
            if (this.timeLockParamter) {
                let lockTime = parseInt(this.timeLockParamter.value) * 60;
                this.idleSettings(lockTime);
            }
        });
    }

    private delay(ms: number): Promise<boolean> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, ms);
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.webSocketDisconnect();
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
