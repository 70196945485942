import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiGateway } from "../../../api-gateway";
import { SnackBarService } from "../../../../cw-utils/snackbar.service";
import { Schedule } from "../../../models/backend-definitions.module";

@Injectable()
export class ScheduleEditProvider implements Resolve<any> {
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    schedule: Schedule;

    constructor(public gw: ApiGateway, public snackBar: SnackBarService) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getSchedule()]).then(() => {
                resolve();
            }, reject);
        });
    }

    getSchedule(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === "novo") {
                this.onDataChanged.next(false);
                resolve(false);
            } else {
                this.gw
                    .get("scheduling/wAll/" + this.routeParams.id)
                    .subscribe((res: Schedule) => {
                        this.schedule = res;
                        this.onDataChanged.next(this.schedule);
                        resolve(res);
                    }, reject);
            }
        });
    }

    getScheduleBySession(sessionId: number): Promise<any> {
        return new Promise((resolve, reject) => {

                this.gw
                    .get("scheduling/session/" + sessionId)
                    .subscribe((res: Schedule) => {
                        this.schedule = res;
                        this.onDataChanged.next(this.schedule);
                        resolve(res);
                    }, reject);
            })
     
    }

    insert(schedule): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduling/", schedule)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    insertExtra(horary): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduling/extra", horary)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    edit(schedule): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put("scheduling/", schedule).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    // this.snackBar.successMessage(response.body.message);
                },
                error:(err =>{
                    reject(err);
                })
            });
        });
    }

    update(schedule, operation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduling/" + operation, schedule)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    updateStatus(scheduleId, type, status): Promise<any> {
        let patch = `scheduling/${scheduleId}/${type}/${status}`;
        if (type === "scheduleCustom/") {
            patch = `scheduling/custom/${scheduleId}/${type}/${status}`;
        }
        return new Promise((resolve, reject) => {
            this.gw.post(patch).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    }

    saveAll(scheduleList): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduling/saveAll", scheduleList)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    delete(schedule): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("scheduling/" + schedule.id)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }
    deleteConfirm(confirmId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete("scheduleConfirm/" + confirmId).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error: (error: HttpResponse<any>) => {
                    reject(error);
                },
            });
        });
    }

    confirm(confirmObj): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduleConfirm/", confirmObj)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    cancel(cancelObj): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("scheduleCancel/", cancelObj).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error(err) {
                    reject;
                },
            });
        });
    }

    block(blockObj): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("scheduleBlock/", blockObj).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    }

    copy(scheduleSource, scheduleTarget, method): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(
                    "scheduling/" +
                        method +
                        "/source/" +
                        scheduleSource +
                        "/target/" +
                        scheduleTarget
                )
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    copyOrMarkdown(scheduleSourceId, scheduleInfo, method): Promise<any> {
        return new Promise((resolve, reject) => {
           this.gw.post(`scheduling/copy-or-markdown/source/${scheduleSourceId}/type/${method}`, scheduleInfo).subscribe({
               next: (response: HttpResponse<any>) => {
                   resolve(true);
               },
               error:(err)=>{
                   reject(err);
               }
           })
        });
    }




    registerScheduleUsage(scheduleId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduling/registerScheduleUsage/" + scheduleId)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }
    updateRegisterScheduleUsage(scheduleId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduling/updateRegisterScheduleUsage/" + scheduleId)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }
    unregisterScheduleUsage(scheduleId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduling/unregisterScheduleUsage/" + scheduleId)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

    unregisterScheduleUsagebyId(): Promise<any> {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            return new Promise((resolve, reject) => {
                this.gw
                    .delete(
                        "scheduling/unregisterScheduleUsage/userId/" + user.id
                    )
                    .subscribe({
                        next: (response: HttpResponse<any>) => {
                            resolve(response);
                        },
                        error: (err) => {
                            reject(err);
                        },
                    });
            });
        }
    }

    unregisterAllScheduleUsage(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("scheduling/unregisterAllScheduleUsage/")
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                }, reject);
        });
    }

    talkPatient(scheduleFilterTO) {
        return new Promise((resolve, reject) => {
            this.gw
                .post("scheduling/talk/patient/", scheduleFilterTO)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                });
        });
    }
}
