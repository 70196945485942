import { Company, Performer, Patient, Person, Procedure, Horary, Environment } from 'cw-backend';
import { Insurance } from '../../accreditation/insurance';
import { User } from '../../security/user/user-model';
import { Operation } from '../../register/operational/operation';
import { Time } from '@angular/common';
import { Session } from '../../attendance/session';
import moment from 'moment/moment';

export class Schedule {
    id: number;
    name: string;
    company: Company;
    performer: Performer;
    patient: Patient;
    person: Person;
    insurance: Insurance;
    procedure: Procedure;
    horary: Horary;
    user: User;
    operation: Operation;
    date: Date;
    hour: Time;
    scheduleNote: ScheduleNote;
    groupName?: string;
    titles?: string;
    shift?: string;
    actions?: string;
    session: Session;
    priority?: string;
    priorityInput?:string;
    scheduleStatus?: string;
    environment?: Environment;
    constructor() {
    }
}

export class ScheduleNote {
    id: number;
    note: string;
}

export class ScheduleFilter {
    company: Company;
    performer: Performer;
    insurance: Insurance;
    procedure: Procedure;
    constructor() {
    }
}
export class HoraryFilter {
    companyId: number;
    day: number;
    constructor() {
    }
}
export class ScheduleViewFilter {
    companyId: number;
    started: string;
    ended: string;
    morning: boolean;
    evening: boolean;
    night: boolean;
    constructor() {
    }

}


export class ScheduleCalendar {

    title: string;
    id: number;
    dateRange: string;
    start: string;
    end: string;
    performerName: string;
    performerId: number;
    procedureName: string;
    patientStatus: string;
    scheduleStatus: string;
    contact: string;
    companyName: string;
    insuranceName: string;
    hour: string;
    personId: number;
    patientId: number;
    note: string;
    userScheduleUserId: number;

}

