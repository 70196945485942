
import { Injectable, Output, EventEmitter } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Horary, Performer } from '../../../models/backend-definitions.module';
import { ApiGateway, PageOptions } from 'cw-backend';
import { CwUtils } from 'cw-utils';

@Injectable()
export class ScheduleListProvider implements Resolve<any>
{
    horary: Horary[];
    scheduling: any[];
    performer: Performer[];
    schedulingEdit: any;
    onDataChanged: BehaviorSubject<any>;
    onHoraryChanged: BehaviorSubject<any>;
    onTypeChanged: BehaviorSubject<any>;
    onPerformerChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.onPerformerChanged = new BehaviorSubject({});
        this.onTypeChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.schedulingEdit = this.state.currentScheduling;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'person.name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions, 'I')
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<HttpResponse<any>> {
        if (!id) {
            return null;
        }
        return this.gw.get('scheduling/:id', { id: id });
    }

    getById(id: number): Observable<HttpResponse<any>> {
        if (!id) {
            return null;
        }
        return this.gw.get('scheduling/wAll/:id', { id: id });
    };

    getScheduleById(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`scheduling/${id}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    getStatusList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('scheduling/scheduleStatus/simpleList')
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    getSimpleObjById(id: number): Observable<HttpResponse<any>> {
        if (!id) {
            return null;
        }

        return this.gw.get('scheduling/simpleObj/:id', { id: id });
    };

    get currentScheduling(): any {
        return this.schedulingEdit;
    }

    getAllByDay(scheduleFilter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/searchByPerformerAndDate', scheduleFilter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    getPage(pageOptions: PageOptions, type, refresh?): Promise<any> {
        if (!type) {
            type = 'I'
        };
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/search/:type', { type: type }, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (refresh || (!CwUtils.compare(this.scheduling, res.body.content) && res.body.content.length > 0)) {
                    this.scheduling = res.body.content;
                    this.onDataChanged.next(this.scheduling);
                }
                else {
                    this.scheduling = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }

    getSimplePage(pageOptions: PageOptions, type): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/search/:type', { type: type }, pageOptions)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    getConfim(scheduleId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("scheduleConfirm/:scheduleId", { scheduleId: scheduleId })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err: any) => { reject(err) }
                })
        })
    }


    search(filter, entity = 'scheduling'): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(entity + '/search', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    searchScheduleList(filter, entity = 'scheduling'): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(entity + '/search/schedule', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    searchScheduleCalendarList(filter, entity = 'scheduling'): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(entity + '/search/schedule/calendar', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    searchScheduleListPatient(filter, entity = 'scheduling'): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(entity + '/search/schedule/patient', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    searchScheduleMedical(filter, entity = 'scheduling'): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(entity + '/search/medical', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    searchAvailable(filter): Promise<any> {

        return new Promise((resolve, reject) => {
            this.gw.post('scheduleAvailableController/searchAvailabilityScheduling', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    searchAvailableSpeciality(scheduleSearch): Promise<any> {

        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/searchAvailabilitySchedulingSpecialityOrPerformer/', scheduleSearch).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (error) => {
                    reject(error)
                }
            });
        });
    }

    searchAll(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/searchPerformerSchedulesDateList', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    getPerformerBySchedule(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/searchPerformerSchedulesDay', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    searchScheduleDateByPerfom(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/searchPerformerSchedulesDate', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }
    searchLog(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduleAud/search', filter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    searchAllForDayOld(filterObj): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/searchPerformerSchedules', filterObj)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    searchAllForDay(filterObj): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/search/schedule/horary', filterObj)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };


    parameterProfessional(performerId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get(`scheduling/parameterProfessional/${performerId}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };




    getFutureScheduleByPatientId(patientId): Promise<any> {
        let path = 'scheduling/patient/' + patientId;
        return new Promise((resolve, reject) => {
            this.gw.get(path)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    getHoraryPerformerShift(companyId, performerId): Promise<any> {

        return new Promise((resolve, reject) => {
            if (!performerId) {
                reject()
                return;
            }

            this.gw.get('scheduling/company/:companyId/performer/:performerId', { companyId: companyId, performerId: performerId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    scheduleTransfer(scheduleTransferObj): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('scheduling/transferSchedule', scheduleTransferObj)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    checkScheduleOrder(scheduleId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('scheduling/check/scheduleOrder/schedule/:scheduleId', { scheduleId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    findScheduleCall(scheduleCallFilter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('schedulingCall/listCall', scheduleCallFilter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    findListOldCall(scheduleCallFilter): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('schedulingCall/listOldCall', scheduleCallFilter)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    finishScheduleCall(scheduleCallId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.put(`schedulingCall/update/${scheduleCallId}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    };

    getPatientStatusList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('scheduling/patientStatus/simpleList')
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getScheduleStatusList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('scheduling/scheduleStatus/simpleList')
                .subscribe((res: HttpResponse<any>) => {
                    resolve(res);
                }, reject);
        });
    }

    getProcedureScheduleList(scheduleId) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`scheduling/procedure/${scheduleId}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {resolve(response)},
                    error: (err) => {reject(err)}
                })
        });
    }
}


