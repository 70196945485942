import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AutoFocusDirectiveDirective } from 'app/layout/components/auto-focus-directive/auto-focus-directive.directive';
import { HighlightDirective } from 'app/layout/components/HighlightDirective/HighlightDirective.directive';
import { MaskNamePipe } from 'app/layout/components/mask-name-pipe/mask-name-pipe.pipe';
import { CustomDatePipe } from './pipes/customDate.pipe';
import {DateTimeFormatPipe} from '../app/layout/components/date-time-format-pipe/date-time-formart-pipe.pipe';


@NgModule({
    declarations: [
        AutoFocusDirectiveDirective,
        HighlightDirective,
        MaskNamePipe,
        DateTimeFormatPipe,
        CustomDatePipe
    ],
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        FlexLayoutModule,
        AutoFocusDirectiveDirective,
        HighlightDirective,
        FuseDirectivesModule,
        FusePipesModule,
        MaskNamePipe,
        CustomDatePipe,
        DateTimeFormatPipe
    ]
})
export class FuseSharedModule
{
}
