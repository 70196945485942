import { HttpResponse } from "@angular/common/http";
import { Location } from '@angular/common';
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiGateway } from "../../../api-gateway";
import { SnackBarService } from "../../../../cw-utils/snackbar.service";
import { Attendance } from "../../../models/backend-definitions.module";

@Injectable()
export class AttendanceEditProvider implements Resolve<any> {
    routeParams: any;
    state: any;
    onDataChanged: BehaviorSubject<any>;
    attendance: Attendance;


    constructor(public gw: ApiGateway, public snackBar: SnackBarService, private location: Location) {
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        this.state = this.location.getState();
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getAttendance()]).then(() => {
                resolve();
            }, reject);
        });
    }

    insertExclusion(exclusionMotive): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.delete('order/ExclusionOrderItem', exclusionMotive).subscribe((response: HttpResponse<any>) => {
                resolve(response);
                this.snackBar.successMessage(response.body.message);
            }, reject);
        });
    }

    delete(exclusionMotive): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("order/delete", exclusionMotive)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getAttendance(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === "novo") {
                this.onDataChanged.next(false);
                resolve(false);
            } else {
                this.gw
                    .get("attendance/wAll/" + this.routeParams.id)
                    .subscribe({
                        next: (res: Attendance) => {
                            this.attendance = res;
                            this.onDataChanged.next(this.attendance);
                            resolve(res);
                        }, error: (err) => { reject(err) }
                    });
            }
        });
    }



    insert(attendance): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/", attendance)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    recalculationparticipation(params, professionalList, insuranceList, updateparticipation,
        updateValues): Promise<any> {

        let orderRecalculation = {
            start: params.start,
            end: params.end,
            professionalList: professionalList,
            insuranceList: insuranceList,
            updateparticipation: updateparticipation,
            updateValues: updateValues
        }
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/recalculation`, orderRecalculation)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }


    duplicateOS(orderId, params): Promise<any> {
        let url: string = '';
        url += `?date=${params.date}`;
        url += `&hour=${params.hour}`;
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/copy/${orderId}/${url}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    duplicateItem(orderItemId, params): Promise<any> {
        let url: string = '';
        url += `?date=${params.date}`;
        url += `&hour=${params.hour}`;
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/order-item/copy/${orderItemId}/${url}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }



    insertCashierOs(cashier): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("cashier/", cashier)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    inserItem(orderItem): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/orderItem", orderItem)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    editItem(orderItem): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/orderItem", orderItem)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    deleteItem(orderItem): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete(`order/orderItem/${orderItem}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    generateNewNumberOrder(orderId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/new-number/${orderId}`)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    edit(attendance): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/", attendance)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    unlockOrder(orderId, context: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/:orderId/unlock/:context", { orderId: orderId, context: context })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    lockOrder(orderId, context: string): Promise<any> {
        if (!orderId) {
            return
        }
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/:orderId/lock/:context", { orderId: orderId, context: context })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    cancelOrderItem(orderItemId){
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/order-item/:orderItemId/cancel", { orderItemId: orderItemId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        })
    }
    undoCancelOrderItem(orderItemId){
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/order-item/:orderItemId/undo-cancellation", { orderItemId: orderItemId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        })
    }

    cancelOrder(orderId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/:orderId/cancel", { orderId: orderId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }
    unCancelOrder(orderId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/:orderId/unCancel", { orderId: orderId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    openOrder(orderId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/:orderId/open", { orderId: orderId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    closeOrder(orderId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .put("order/:orderId/close", { orderId: orderId })
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    lockAllOrder(filterTO): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/lockAll", filterTO)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    lockAllOrderById(orderIdList, type): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/lockOrUnlockAll/list/${type}`, orderIdList)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    closeAllOrderById(orderIdList): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/closeAllOrderById/list/`, orderIdList)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    update(attendance, operation): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("attendance/" + operation, attendance)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getSessionList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(`order/order-sessions`)
                .subscribe({
                    next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
                    error(error) { reject(error) }
                });
        });
    }

    saveSession(session): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/order-session/", session)
                .subscribe({
                    next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
                    error(error) { reject(error) }
                });
        });
    }

    saveSessionItem(sessionItem): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("order/session-item/", sessionItem)
                .subscribe({
                    next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
                    error(error) { reject(error) }
                });
        });
    }

    deleteSessionItem(sessionItemId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete("order/session-item/:sessionItemId", { sessionItemId: sessionItemId })
                .subscribe({
                    next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
                    error(error) { reject(error) }
                });
        });
    }

    authorizer(orderId: number) {
        return new Promise<any>((resolve, reject) => {
            this.gw.post(`authorizerTissController/${orderId}`).subscribe({
                next: (res: any) => {
                    resolve(res);
                },
                error: (err: any) => { reject(err); }
            });
        })
    }

    insertOrderPayer(orderPayer): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("orderPayer/", orderPayer)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                        this.snackBar.successMessage(response.body.message);
                    }, error: (err) => { reject(err) }
                });
        });
    }

    getOrderPayerByOrderId(orderId: number) {
        return new Promise<any>((resolve, reject) => {
            this.gw.get(`orderPayer/order/${orderId}`).subscribe({
                next: (res: any) => {
                    resolve(res);
                },
                error: (err: any) => { reject(err); }
            });
        })
    }

    deleteOrderPayer(orderPayerId: number) {
        return new Promise((resolve, reject) => {
            this.gw
                .delete(`orderPayer/${orderPayerId}`)
                .subscribe({
                    next: (res: HttpResponse<any[]>) => { res.body ? resolve(res.body) : resolve(res) },
                    error(error) { reject(error) }
                });
        });
    }

    updateEnrollmentPatient(enrollment: string, patientId: number, orderId : number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`order/updateEnrollment/${patientId}/${orderId}`, enrollment)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);       
                        this.snackBar.successMessage(response.body.message);                
                    }, error: (err) => { reject(err) }
                });
        });
    }

}
