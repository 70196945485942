<h1 matDialogTitle *ngIf="action != 'cancel' && action != 'clear'">Confirmação</h1>
<h1 matDialogTitle *ngIf="action == 'cancel' || action == 'clear'">Atenção</h1>
<div mat-dialog-content [ngSwitch]="action">
    <p *ngSwitchCase="'delete'"> {{deleteMessage}}</p>
    <p *ngSwitchCase="'confirm'"> {{confirmMessage}}</p>
    <p *ngSwitchCase="'cancel'"> {{cancelMessage}}</p>
    <p *ngSwitchCase="'clear'"> {{clearMessage}}</p>
    <p *ngSwitchDefault> {{action}}</p>
</div>
<div mat-dialog-actions class="pt-24">
    <button *ngIf="!scheduleAction && !scheduleActionCalendar" mat-raised-button class="mat-accent mr-16" (click)="dialogRef.close(true)">
        <p *ngIf="action != 'clear' && action != 'cancel'">Confirmar</p>
        <p *ngIf="action == 'clear'">Descartar</p>
        <p *ngIf="action == 'cancel'">Sim</p>
    </button>
    <button *ngIf="scheduleAction" mat-stroked-button class="mat-accent mr-16" (click)="dialogRef.close('os')">
        <p *ngIf="action != 'clear' && action != 'cancel'">Ir para a guia</p>
    </button>
    <button *ngIf="scheduleAction" mat-stroked-button class="mat-accent mr-16" (click)="dialogRef.close('list')">
        <p *ngIf="action != 'clear' && action != 'cancel'">Ir para listagem</p>
    </button>
    <button *ngIf="scheduleAction" mat-stroked-button class="mat-accent mr-16" (click)="dialogRef.close('print')">
        <p *ngIf="action != 'clear' && action != 'cancel'">Imprimir Guia</p>
    </button>
    <button mat-button *ngIf="action == 'clear'" class="mat-accent mr-16" (click)="dialogRef.close('save')">
        <p>Salvar e sair</p>
    </button>
    <button *ngIf="scheduleActionCalendar" mat-stroked-button class="mat-accent mr-16" (click)="dialogRef.close('copy')">
        <p *ngIf="action != 'clear' && action != 'cancel'">Copiar</p>
    </button>
    <button *ngIf="scheduleActionCalendar" mat-stroked-button class="mat-accent mr-16" (click)="dialogRef.close('markdown')">
        <p *ngIf="action != 'clear' && action != 'cancel'">Remarcar</p>
    </button>
    <button mat-button (click)="dialogRef.close(false)">
        <p>{{action != 'cancel'? 'Cancelar': 'Não'}}</p>
    </button>
</div>
