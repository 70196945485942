import { Contact } from "./../../../../src/app/main/apps/contacts/contact.model";
import { ProcedureSpecs } from "./procedure";
import { Agreement } from "./agreement";
import { Quotation } from "./quotation";
import { Address, Plan, Performer, TableType, Accreditation } from "cw-backend";
import { ImageObj } from "../interface/image-obj";

export class Insurance {
    id: number;
    insuranceParent: Insurance;
    image: ImageObj;
    name: string;
    alias: string;
    type: string;
    cnpj: string;
    ansRegistry: string;
    municipalRegistry: string;
    stateRegistry: string;
    providerCode: string;
    cnes: string;
    insuranceTax: InsuranceTax;
    insuranceTiss: InsuranceTiss;
    planList: InsurancePlan[];
    procedureSpecsList: ProcedureSpecs[];
    agreementList: Agreement[];
    quotationList: Quotation[];
    addressList: Address[];
    contactList: Contact[];
    paymentModel: string;
    insuranceCoparticipation: Insurance;
    performer: Performer;
    active: boolean;
}

export class InsuranceTax {
    id: number;
    medic: number;
    inss: number;
    iss: number;
    ir: number;
    irCeiling: boolean;
    pis: number;
    cofins: number;
    csl: number;
    pisCofinsCslCeiling: boolean;
    simpleTax: number;
    insurance: Insurance;
}

export class InsuranceTiss {
    id: number;
    insurance: Insurance;
    version: string;
    delivery: number;
    lot: number;
    procedureTableType: TableType;
    medicamentTableType: TableType;
    materialTableType: TableType;
    feeTableType: TableType;
    contraptionConsultation: string;
    contraptionSpsadt: string;
    contraptionAdmission: string;
    contraptionHonorary: string;
}

export class InsurancePlan {
    id: number;
    insuranceId: number;
    plan: Plan;
}

export class InsuranceSimple {
    id: number;
    name: number;
    insuranceTiss: InsuranceTiss;
    paymentModel: string;
    planList: InsurancePlan[];
}

export class InsuranceDiscountRule {
    id: number;
    insurance: Insurance;
    accreditation: Accreditation;
    type: String;
    percent2th: number;
    percent3th: number;
    percent100LargestPort: boolean;
    daily: boolean;
    representationReductionAdditional: boolean;
    percentViaAccess1: number;
    percentViaAccess2: number;
    percentNightShift: number;
    percentInfirmary: number;
    percentApartament: number;
    percentSuite: number;
}

export class InsurancePlanTO {
    namePlan: string;
    insuranceId: number;
}
