import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiGateway } from "cw-backend";
import { SnackBarService } from "cw-utils";
import { ParameterNameEnum } from "../../../models/application/parameter/parameter-name-enum.enum";
import { Environment } from "app/decorators/environment.decorator";
import { TenantService } from "app/tenant/tenant.service";
import { ParameterInsert } from "../../../models/register/organizational/parameter-insert";

@Injectable()
export class ParameterProvider implements Resolve<any> {
    @Environment("baseUrl") baseUrl: string;
    parameter: any[];
    onDataChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;

    constructor(
        public gw: ApiGateway,
        public snackBar: SnackBarService,
        public http: HttpClient,
        public tenantService: TenantService
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getAll()]).then(() => {
                resolve();
            }, reject);
        });
    }

    get(id: number): Observable<any> {
        if (!id) {
            return null;
        }
        return this.gw.get("parameter/:id", { id: id });
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("parameter/frontEnd").subscribe({
                next: (res: HttpResponse<any>) => {
                    this.parameter = res.body;
                    this.onDataChanged.next(this.parameter);
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    getContextMenu(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get("parameter/contextType/simpleList").subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    getParameterList(context, group): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("parameter/context/:context/group/:group", {
                    context: context,
                    group: group,
                })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    getGroupMenu(context): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("parameter/context/:context/", { context: context })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    getGroupMenuByContextAndKey(context, key, pageOptions?): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`parameter/context/${context}/key/${key}/`, pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    searchParameter(context, desc): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("parameter/context/:context/:desc", {
                    context: context,
                    desc: desc,
                })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }
    getValueByParameterOrParameterValueParameter(name, key): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get("parameter/name/:name/key/:key", { name: name, key: key })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    getValueByExameTypeStandard(procedureId, accreditationId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .get(
                    "parameter/exameTypeStandard/:procedureId/:accreditationId",
                    {
                        procedureId: procedureId,
                        accreditationId: accreditationId,
                    }
                )
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    getByName(name): Promise<any> {
        const protocol = window.location.protocol; // Retorna 'http:' ou 'https:'
        const url = window.location.href;
        const match = url.match(/https?:\/\/(.*?)\./); // Suporta tanto http quanto https
        let tenant = match ? match[1] : null;

        const headers = new HttpHeaders({
            "X-Tenantid": tenant, // Substitua pelo nome e valor do seu header
        });

        return new Promise((resolve, reject) => {
            this.http
                .get(`parameter/name/${name}`, { headers: headers })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    getByNameNotAuthentication(name: string): Promise<any> {
        let tenant = this.tenantService.getTenant();

        return new Promise((resolve, reject) => {
            // Criar os headers
            const headers = new HttpHeaders({
                "X-Tenantid": tenant,
            });

            // Fazer a requisição GET com headers
            this.http
                .get(`${this.baseUrl}parameter/name/${name}`, {
                    headers: headers,
                })
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        res.body ? resolve(res.body) : resolve(res);
                    },
                    error: (err: any) => {
                        reject(err);
                    },
                });
        });
    }

    save(paramterList): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post("parameter/", paramterList).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }
    // /key/{key}/parameter-value
    getParameterValueByKeyAndNames(
        key,
        names: ParameterNameEnum[]
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post(`parameter/key/${key}/parameter-value`, names)
                .subscribe({
                    next: (response: HttpResponse<any>) => {
                        resolve(response);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
        });
    }

    uploadLogoCompany(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const endpoint = this.baseUrl + "parameter/upload-logo";
            const formData: FormData = new FormData();
            formData.append("file", file, file.name);
            formData.append("type", file.type);

            let authToken = localStorage.getItem("token");
            let headers = new HttpHeaders();
            headers = headers.append("X-Auth-Token", authToken);

            this.http.post(endpoint, formData, { headers: headers }).subscribe({
                next: (response: HttpResponse<any>) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    insertParameterValue(parameterInsert: ParameterInsert): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .post("parameter/parameterValue", parameterInsert)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }

    deleteParameterValue(parameterValueId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw
                .delete(`parameter/parameterValue/${parameterValueId}`)
                .subscribe((response: HttpResponse<any>) => {
                    resolve(response);
                    this.snackBar.successMessage(response.body.message);
                }, reject);
        });
    }
}
