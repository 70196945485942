import * as Stomp from "stompjs";
import * as SockJS from "sockjs-client";

import { AppComponent } from "app/app.component";
import {
    Output,
    EventEmitter,
    Injectable,
    Directive,
    OnDestroy,
} from "@angular/core";
import { Environment } from "app/decorators/environment.decorator";
import { TenantService } from "app/tenant/tenant.service";
import ReconnectingWebSocket from "reconnecting-websocket";
import { Client, StompConfig } from "@stomp/stompjs";

@Injectable()
export class WebSocketService implements OnDestroy {
    @Output() messageReceived: EventEmitter<any> = new EventEmitter();
    // baseUrl = 'http://localhost:6379'
    @Environment("baseUrlWs") baseUrl: string;
    webSocketEndPoint: string;
    topic: string = "/user/topic/reply";
    messageTopic: string = "/user/topic/message";
    updateProfileTopic: string = "/topic/updateProfile";
    parateterTopic: string = "/topic/refreshParameter";

    scheduleDinamicTopic: string = "/topic/refreshSchedule";
    scheduleCalendarDinamicTopic: string = "/topic/refreshScheduleCalendar";

    stompClient: any;
    socket: ReconnectingWebSocket;
    appComponent: AppComponent;

    webSocketeService: WebSocketService;

    tenantService: TenantService;

    subscriptions: { [key: string]: any } = {}; // Armazena as subscrições por tópico

    dinamicSubscriptions: { [key: string]: any } = {}; // Armazena as subscrições por tópico

    constructor() {
        this.tenantService = new TenantService();
    }
    ngOnDestroy(): void {
        //console.log("web socket disconectado");
        this._disconnect();
    }

    _connect() {
        let token = localStorage.getItem("token");
        let user = JSON.parse(localStorage.getItem("user"));
        if (user == null) {
            setTimeout(() => {
                user = JSON.parse(localStorage.getItem("user"));
            }, 2000);
            if (user == null) {
                return false;
            }
        }
        const tenantPath = this.tenantService.getTenant();

        this.webSocketEndPoint =
            this.baseUrl + tenantPath + "/?X-Auth-Token=" + token;
        this.socket = new ReconnectingWebSocket(this.webSocketEndPoint, [], {
            debug: false,
            reconnectionDelayGrowFactor: 20000, // Tentar reconectar a cada 5 segundos
            maxRetries: 10, // Tentar reconectar no máximo 10 vezes
        });

        // Adiciona um listener para o evento "open", que é disparado quando a conexão WebSocket é estabelecida com sucesso
        this.socket.addEventListener("open", (event) => {
            console.log("Conexão estabelecida com sucesso");
        });

        // Adiciona um listener para o evento "message", que é disparado quando uma mensagem é recebida pelo WebSocket
        this.socket.addEventListener("message", (event) => {
        });

        // Adiciona um listener para o evento "error", que é disparado quando ocorre algum erro na conexão WebSocket
        this.socket.addEventListener("error", (event) => {
            console.error("Erro na conexão:", event);
            let user = JSON.parse(localStorage.getItem("user"));
            if (user == null) {
                this._disconnect();
            }
        });

        // Adiciona um listener para o evento "close", que é disparado quando a conexão WebSocket é fechada
        this.socket.addEventListener("close", (event) => {
            console.log("Conexão fechada: -----------------");
        });

        this.stompClient = Stomp.over(this.socket);
        this.stompClient.debug = null;
        const _this = this;
        _this.stompClient.connect(
            (user = { user: `${user.id}`, token: token }),
            function (frame) {
                // Subscribing to various topics using the new method
                _this.subscribeToTopic(_this.topic, tenantPath);
                _this.subscribeToTopic(_this.messageTopic, tenantPath);
                _this.subscribeToTopic(_this.updateProfileTopic, tenantPath);
                _this.subscribeToTopic(_this.parateterTopic, tenantPath);

                // Reinscreva-se em tópicos dinâmicos
                _this.reconnectDynamicSubscriptions();
            }
        );
    }

    reconnectDynamicSubscriptions() {
        const tenantPath = this.tenantService.getTenant();

        // Reinscreva-se em todos os tópicos dinâmicos
        Object.keys(this.dinamicSubscriptions).forEach((topic) => {
            const fullTopic = `${topic}-${tenantPath}`;
            const subscription = this.stompClient.subscribe(fullTopic, (sdkEvent) => {
                this.onMessageReceived(sdkEvent);
            });
            this.dinamicSubscriptions[topic] = subscription; // Atualize a referência da subscrição
            //console.log(`Reconnected to ${fullTopic}`);
        });
    }


    subscribeToTopic(topic: string, tenantPath: string) {
        const fullTopic = `${topic}-${tenantPath}`;
        const subscription = this.stompClient.subscribe(
            fullTopic,
            (sdkEvent) => {
                this.onMessageReceived(sdkEvent);
            }
        );
        this.subscriptions[fullTopic] = subscription; // Armazena a subscrição
      //  console.log(`Subscribed to ${fullTopic}`);
    }


    unsubscribeTopic(topic: string, tenantPath: string) {
        // Limpa todas as subscrições armazenadas
        const fullTopic = `${topic}-${tenantPath}`;
       // console.log(this.subscriptions);
        const subscription = this.subscriptions[fullTopic];
        if (subscription) {
            subscription.unsubscribe(); // Cancela a subscrição
            delete this.subscriptions[fullTopic]; // Remove a referência armazenada
            console.log(`Unsubscribed from ${fullTopic}`);
        } else {
            console.log(`No subscription found for ${fullTopic}`);
        }
    }

    subscribeSchedule() {
        const tenantPath = this.tenantService.getTenant();
        this.dinamicSubscriptions[this.scheduleDinamicTopic] = true; // Marque como ativo
        this.subscribeToTopic(this.scheduleDinamicTopic, tenantPath);
    }

    unsubscribeSchedule() {
        const tenantPath = this.tenantService.getTenant();
        delete this.dinamicSubscriptions[this.scheduleDinamicTopic]; // Remova a marcação
        this.unsubscribeTopic(this.scheduleDinamicTopic, tenantPath);
    }

    subscribeScheduleCalendar() {
        const tenantPath = this.tenantService.getTenant();
        this.dinamicSubscriptions[this.scheduleCalendarDinamicTopic] = true; // Marque como ativo
        this.subscribeToTopic(this.scheduleCalendarDinamicTopic, tenantPath);
    }

    unsubscribeScheduleCalendar() {
        const tenantPath = this.tenantService.getTenant();
        delete this.dinamicSubscriptions[this.scheduleCalendarDinamicTopic]; // Remova a marcação
        this.unsubscribeTopic(this.scheduleCalendarDinamicTopic, tenantPath);
    }


    _disconnect() {
        if (this.stompClient !== null) {
            // this.socket.close();
            const tenantPath = this.tenantService.getTenant();
            let simpDisconectMessage;
            this.stompClient.disconnect(
                null,
                (simpDisconectMessage = { tenant: tenantPath })
            );
            console.log("87 web socket disconectado");
            delete this.stompClient;
            delete this.socket;
        }
    }

    // on error, schedule a reconnection attempt
    errorCallBack() {
        this.stompClient.onWebSocketClose = (e) => {};
    }

    _send(message) {

    }

    onMessageReceived(message) {
        let token = localStorage.getItem("token");
        if (token) {
            this.messageReceived.emit(message);
        } else if (message.body != "EXPIREPASSWORD") {
            this._disconnect();
        }
    }

    scheduleView(message) {
        let token = localStorage.getItem("token");
        if (token) {
            this.messageReceived.emit(message);
        }
    }
}
