import { Kit, ProcedureCode } from 'cw-backend';

export class ProcedureSimpleObj {
    id: number;
    name: string;
    alias: string;
    active: boolean;
    currency: any;
    value:number;
    procedureKitList: Kit[];
    procedureCodeList: ProcedureCode[];
}