
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Professional, PageOptions, ApiGateway } from 'cw-backend';
import { CwUtils } from 'cw-utils';
import { Horary, Performer } from '../../../models/backend-definitions.module';

@Injectable()
export class ProfessionalListProvider implements Resolve<any>
{
    horary: Horary[];
    professional: Professional[];
    performer: Performer[];
    professionalEdit: Professional;
    onDataChanged: BehaviorSubject<any>;
    onHoraryChanged: BehaviorSubject<any>;
    onTypeChanged: BehaviorSubject<any>;
    onPerformerChanged: BehaviorSubject<any>;
    routeParams: any;
    state: any;
    pageOptions: PageOptions;
    currentPage: PageOptions;

    constructor(
        public gw: ApiGateway,
        private location: Location,
    ) {
        this.onDataChanged = new BehaviorSubject({});
        this.onPerformerChanged = new BehaviorSubject({});
        this.onTypeChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        // Get state for current or new page
        this.state = this.location.getState();
        if (this.state.currentPage && state.url.includes(this.state.path)) {
            this.currentPage = this.state.currentPage;
        }
        this.professionalEdit = this.state.currentProfessional;

        if (this.currentPage) {
            this.pageOptions = this.currentPage;
        }
        else {
            this.pageOptions = new PageOptions();
            this.pageOptions.fieldSort = 'person.name';
        }
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPage(this.pageOptions, 'I')
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    get(id: number): Observable<HttpResponse<Professional>> {
        if (!id) {
            return null;
        }
        return this.gw.get('professional/:id', { id: id });
    }

    getPerformerById(id: number): Observable<HttpResponse<Professional>> {
        if (!id) {
            return null;
        }
        return this.gw.get('professional/performer/:id', { id: id });
    }

    performerById(id: number): Observable<HttpResponse<Performer>> {
        if (!id) {
            return null;
        }
        return this.gw.get('professional/performer/:id', { id: id });
    }

    findPerformerById(id: number): Observable<HttpResponse<Performer>> {
        if (!id) {
            return null;
        }
        return this.gw.get('professional/performer/memed/:id', { id: id });
    }

    getById(id: number): Observable<HttpResponse<Professional>> {
        if (!id) {
            return null;
        }
        return this.gw.get('professional/wAll/:id', { id: id });
    }

    getByIdAbridged(id: number): Observable<HttpResponse<Professional>> {
        if (!id) {
            return null;
        }
        return this.gw.get('professional/abridged/:id', { id: id });
    }

    getSimpleObjById(id: number): Observable<HttpResponse<Professional>> {
        if (!id) {
            return null;
        }

        return this.gw.get('professional/simpleObj/:id', { id: id });
    };

    get currentProfessional(): Professional {
        return this.professionalEdit;
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('professional/').subscribe((res: HttpResponse<any>) => {
                this.professional = res.body;
                resolve(res);
            }, reject);
        });

    }

    getPage(pageOptions: PageOptions, type, refresh?): Promise<any> {
        if (!type) {
            type = 'I'
        };
        return new Promise((resolve, reject) => {
            this.gw.post('professional/search/:type', { type: type }, pageOptions).subscribe((res: HttpResponse<any>) => {
                if (refresh || (!CwUtils.compare(this.professional, res.body.content) && res.body.content.length > 0)) {
                    this.professional = res.body.content;
                    this.onDataChanged.next(this.professional);
                }
                else {
                    this.professional = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    }


    getProfessionalByMultipleIdsAndType(idList, type): Promise<any> {
        let urlPath = `professional/find-multiple-ids/${type}`;

        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, idList).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err => reject(err))
            });
        });
    }

    getPerformerList(pageSearchTO): Promise<any> {
        let urlPath = `professional/search/performer`;

        return new Promise((resolve, reject) => {
            this.gw.post(urlPath, pageSearchTO).subscribe({
                next: (res: HttpResponse<any>) => {
                    resolve(res);
                }, error: (err => reject(err))
            });
        });
    }

    getSimplePage(pageOptions: PageOptions, type): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/search/simple/:type', { type: type }, pageOptions).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getProfessionalSearch(pageOptions: PageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/search', pageOptions).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getPerformerPage(pageOptions: PageOptions, entity): Promise<any> {
        let path = entity + 'professional/performer/search/'
        return new Promise((resolve, reject) => {
            this.gw.post(path, pageOptions).subscribe((res: HttpResponse<any>) => {

                if (!CwUtils.compare(this.professional, res.body.content) && res.body.content.length > 0) {
                    this.performer = res.body.content;
                    this.onDataChanged.next(this.performer);
                }
                else {
                    this.performer = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    };

    getPerformerPageNoEntity(pageOptions: PageOptions): Promise<any> {
        let path = 'professional/performer/search/'
        return new Promise((resolve, reject) => {
            this.gw.post(path, pageOptions).subscribe((res: HttpResponse<any>) => {

                if (!CwUtils.compare(this.professional, res.body.content) && res.body.content.length > 0) {
                    this.performer = res.body.content;
                    this.onDataChanged.next(this.performer);
                }
                else {
                    this.performer = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    };

    getPerformerAllPageNoEntity(pageOptions: PageOptions): Promise<any> {
        let path = 'professional/performer/searchAll/'
        return new Promise((resolve, reject) => {
            this.gw.post(path, pageOptions).subscribe((res: HttpResponse<any>) => {

                if (!CwUtils.compare(this.professional, res.body.content) && res.body.content.length > 0) {
                    this.performer = res.body.content;
                    this.onDataChanged.next(this.performer);
                }
                else {
                    this.performer = res.body.content;
                };
                resolve(res);
            }, reject);
        });
    };

    getPerformerBySpeciality(pageOptions: PageOptions, specialityId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`scheduling/professional/search/speciality/${specialityId}`, pageOptions).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    };

    getPerformerByFilter(pageOptions, company?, insurance?, speciality?,
        procedure?): Promise<any> {

        const body = {
            insuranceId: insurance ? insurance : null,
            procedureId: procedure ? procedure : null,
            companyId: company ? company : null,
            specialityId: speciality ? speciality : null,
            pageSearchTO: pageOptions,
            //onlyOpenSchedule: true,
        };


        return new Promise((resolve, reject) => {
            this.gw.post("professional/performer/searchByFilter", body).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    };

    getPersonImageListId(personId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('professional/person/:personId/image/search', { personId: personId }).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });

    };

    getRegistryTypeList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('professional/registryType/simpleList').subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });

    }

    getPageSettings() {
        if (this.currentPage) {
            return this.pageOptions;
        } else {
            return;
        }
    }

    getHoraryByPerformerId(performerId, pageOptions: PageOptions, renew?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post('professional/perfomer/' + performerId + '/search/horary', pageOptions).subscribe((res: HttpResponse<any>) => {
                if ((!CwUtils.compare(this.horary, res.body.content) && res.body.content.length > 0) || renew) {
                    this.horary = res.body.content;
                    this.onHoraryChanged.next(this.horary);
                } else {
                    this.horary = res.body.content;
                }
                resolve(res);
            }, reject);
        });
    }

    getCompanyPerformerCustomEnum(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.get('professional/companyPerformerCustomEnum/simpleList')
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error(err) {
                        reject(err);
                    },
                })
        });
    }

    getCompanyPerformerCustomList(performerId, pageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`professional/companyPerformerCustom/${performerId}`,pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error(err) {
                        reject(err);
                    },
                })
        });
    }

    getPagePatientActived(pageOptions: PageOptions, performerId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`professional/search/patientActived/${performerId}`, pageOptions).subscribe((res: HttpResponse<any>) => {
                resolve(res);
            }, reject);
        });
    }

    getOrderPendingExclusion(performerId, pageOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            this.gw.post(`professional/order-pending-exclusion/${performerId}`,pageOptions)
                .subscribe({
                    next: (res: HttpResponse<any>) => {
                        resolve(res);
                    },
                    error(err) {
                        reject(err);
                    },
                })
        });
    }
}


