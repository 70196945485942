import { Pipe, PipeTransform } from "@angular/core";
import moment from 'moment';
import {CwUtils} from 'cw-utils';

@Pipe({
    name: 'DateTimeFormatPipe',
    pure: false,
})
export class DateTimeFormatPipe implements PipeTransform {

    transform(date: string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
        moment.locale('pt-br')
        if(!CwUtils.checkStringIsNull(date)){
            return date;
        }
        if (format === 'CALENDAR'){
          return moment(date).calendar();
        }else{
            return moment(date).format(format);
        }
    }
}
